import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class DHL extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
                        <div className="container mt-10 my-5">
    <div class="col-md-4">
                        <p class="breadcrumbs mb-5">
                            <span className='send-breadcrumb'><a href="/">Home <i class="ion-ios-arrow-forward"></i></a><a href="/dhl" className='ml-1'>DHL</a></span>
                            
                             </p></div>
        <h1 className="mb-4">Why send a parcel with DHL?</h1>
        <ul className="list-unstyled">
            <li>Free parcel tracking on all services</li>
            <li>Next day UK delivery available</li>
            <li>Delivery notifications via text</li>
            <li>Send up to 25kg per parcel</li>
        </ul>
        <p className="font-weight-bold">Send a parcel from only £8.15 exc VAT</p>

        <h2 className="mt-5">About DHL Couriers</h2>
        <p>
            DHL UK is one of the most well-known and trusted brands around the globe. It was created in San Francisco in 
            the USA in 1969, but it is now owned by German logistics company Deutsche Post. Currently present in over 220 
            countries and territories, DHL UK strives to be the leading logistics company for UK and international needs.
        </p>
        <p>
            Offering a range of express logistical solutions that are focused around their customers, DHL home delivery makes 
            sending parcels simpler. That’s why Parcellodge is proud to include them in our extensive delivery network.
        </p>
        <p>
            Get a DHL quote now with our quick quote tool - prices start from £8.09. Book your DHL shipping through Parcellodge 
            and get reliable and safe door to door delivery.
        </p>

        <h2 className="mt-5">DHL UK Services</h2>
        <p>
            With over 42 depots and 1,200 service points across the UK, you can rest assured that DHL UK has a service that’s right 
            for you! You can either book a DHL collection at a place of your choosing, or you can book DHL Drop Off Services if that is 
            more convenient for your needs. DHL also offer UK Multi parcel delivery, for those wanting to send more than one parcel at once.
        </p>

        <h2 className="mt-5">Why choose DHL delivery with Parcellodge?</h2>
        <ul className="list-unstyled">
            <li>A lowest price guarantee on deliveries to Europe and the UK</li>
            <li>24 hour customer service</li>
            <li>The UK's number one comparison site</li>
        </ul>

        <h2 className="mt-5">FAQs</h2>
        <h5>How much does it cost to use DHL for parcel collection?</h5>
        <p>
            Find out how much DHL shipping will be using our quick quote tool. The cheapest prices for DHL collection start from £8.09 exc VAT.
        </p>

        <h5>What type of courier services does DHL collection offer?</h5>
        <p>
            DHL UK offers a range of services for your needs: DHL UK, DHL next day delivery with morning slots available. Whether it’s a large parcel 
            or simple post, the DHL courier service can get your items door to door. You can also book DHL Drop Off Services if that is preferred.
        </p>

        <h5>Can DHL collect parcels from home?</h5>
        <p>
            Yes, you can book DHL collection from home. Simply use our quick quote tool to get started.
        </p>

        <h5>How do I arrange a DHL parcel collection service?</h5>
        <p>
            Book your DHL collection with our quick quote tool. DHL couriers will pick up your parcel and deliver it to its destination with door to door delivery.
        </p>

        <h5>What time do DHL couriers deliver until?</h5>
        <p>
            Delivery times are from 7am until 8pm, Monday to Friday. DHL tracks delivery across the whole journey and provides a 1-hour time slot on the day of delivery for your convenience.
        </p>

        <h5>Does DHL deliver on Saturdays and Sundays?</h5>
        <p>
            We don't currently offer weekend delivery with DHL. If you need your parcel to be delivered on the weekend, see our weekend delivery options.
        </p>
    </div>



                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default DHL;
        