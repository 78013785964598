import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class EVRI extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
                        <div className="container mt-10 my-5">
    <div class="col-md-4">
                        <p class="breadcrumbs mb-5">
                            <span className='send-breadcrumb'><a href="/">Home <i class="ion-ios-arrow-forward"></i></a><a href="/evri" className='ml-1'>EVRI</a></span>
                            
                             </p></div>
        <h1 className="text-primary mb-4">Why send a parcel with Evri Drop-off?</h1>
        <ul className="list-unstyled">
            <li><i className="fas fa-check text-success"></i> Over 7,000 drop-off locations across the UK</li>
            <li><i className="fas fa-check text-success"></i> Parcel protection up to £5,000 on selected services</li>
            <li><i className="fas fa-check text-success"></i> Send up to 15kg per parcel</li>
            <li><i className="fas fa-check text-success"></i> Free parcel tracking on all services</li>
            <li><i className="fas fa-check text-success"></i> Option to print your labels in store for FREE</li>
        </ul>
        <p><strong>Send a parcel from only £2.15 exc VAT</strong></p>

        <h2 className="text-secondary mt-5">About Evri Drop-off Couriers</h2>
        <p><strong>What is Evri Drop Off?</strong></p>
        <p>With over 7,000 Evri drop off points and Parcelshops across the UK, it’s easy to see why it is one of the nation’s most popular courier services. Evri Drop-Off currently handles over 260 million parcels every year, aided by the efficiency and trust customers have in its no-fuss approach to getting the job done. Its dedication to the environment means you can choose to make your delivery eco-friendly.</p>
        <p>Evri Drop-Off offers a range of services to fit many parcel sizes and budgets. If you need a more urgent courier service, you can find your nearest Evri drop-off locations for next-day delivery. It’s a great way to send your goods more efficiently so they get to their destination when they’re supposed to.</p>
        <p>Evri couriers offer both individuals and businesses the opportunity to send more easily with hundreds of e-commerce shops online such as eBay, Amazon, Shopify and many more!</p>
        <p>Not only does Evri Drop-Off deliver efficiently, but they also offer competitive prices to suit your budgets. Our long-standing relationship with Evri Drop-Off allows us to exclusively negotiate the best possible prices for you.</p>

        <h3 className="text-secondary mt-4">Evri ParcelShop Service</h3>
        <p>Drop your parcel off at any of the 7,000 Evri drop-off locations known as Evri Parcelshops around the UK. Your parcel will be delivered within 2-5 working days, after the parcel has been collected from your nearest Evri drop-off location. With Evri ParcelShop, you can print in store and get an Evri ParcelShop receipt to get proof of postage.</p>

        <h4 className="mt-4">Send up to 15kg with Evri Drop Off</h4>
        <p>Send parcels weighing up to 15kg, with a length and girth up to 2.45m! No matter what size your parcel is, Evri delivery lets you ship your items for the lowest possible price.</p>

        <h4 className="mt-4">International Evri ParcelShop</h4>
        <p>Send a parcel from wherever you are in the UK to Europe for a great price with Evri drop-off services. Drop your parcel off at the Evri drop-off near you (such as your local Evri ParcelShop location) and it will be delivered within 3-7 working days throughout most of Europe.</p>

        <h2 className="text-secondary mt-5">Why choose Evri Drop-off delivery with Parcellodge?</h2>
        <p>When you book your Evri Drop-Off service through Parcellodge you can rest assured that your parcel will get from A to B without a hitch. Evri is a leading name in UK parcel delivery because of their no-fuss approach to getting the job done promptly and, when you book through Parcellodge, you’ll get the lowest possible price alongside our renowned quality of care.</p>
        <ul className="list-unstyled">
            <li><i className="fas fa-check text-success"></i> A lowest price guarantee on deliveries to Europe and the UK</li>
            <li><i className="fas fa-check text-success"></i> 24 hour customer service</li>
            <li><i className="fas fa-check text-success"></i> The UK's number one comparison site</li>
        </ul>

        <h2 className="text-secondary mt-5">FAQs</h2>
        <h5>How does Evri drop off work?</h5>
        <p>Evri Drop-Off services allow you to go to any one of Evri’s ParcelShops and drop off your parcels. You can go to Evri Drop-Off with no printer at home - the Evri ParcelShop can print in store for you. Evri ParcelShops drop-off points are open 7 days a week for your convenience.</p>
        <p>You can also use Evri Collection services if you’d rather have your parcel collected from home or a location of your choice.</p>

        <h5>Can I drop an Evri parcel at an InPost locker?</h5>
        <p>Yes, in some cases you can drop off your Evri parcel at an InPost locker. Search for your nearest Evri drop-off point using our quick quote tool.</p>

        <h5>Where is my nearest Evri Parcelshop?</h5>
        <p>Find out your nearest Evri Parcelshop using our quick quote tool. There are thousands of Evri ParcelShop locations across the UK, meaning you can find your most convenient ParcelShop easily.</p>

        <h5>How long do Evri Parcelshops keep parcels?</h5>
        <p>Evri Parcelshops can hold onto parcels for up to 7 working days.</p>

        <h5>Can you print an Evri label in store?</h5>
        <p>Yes, with Evri ParcelShops’ print in store facilities, you can go to Evri and drop off with no printer at home. Your Evri delivery service will print the label for you.</p>
    </div>


                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default EVRI;
        