import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // This includes Popper.js


import Header from './Header';
import Footer from './Footer';

class Pallet extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            

                        <div className="container mt-10 my-5">
    <div class="col-md-4">
                        <p class="breadcrumbs mb-5">
                            <span className='send-breadcrumb'><a href="/">Home <i class="ion-ios-arrow-forward"></i></a><a href="/pallet" className='ml-1'>Palletways</a></span>
                            
                             </p></div>
        <h1 className="mb-4">Why Send a Pallet with Palletways?</h1>
        <ul className="list-group circle-list mb-4">
            <li className="list-group-item">Option for Saturday delivery</li>
            <li className="list-group-item">Parcel protection available on selected services</li>
            <li className="list-group-item">Send up to 1,200kg per pallet</li>
            <li className="list-group-item">Free pallet tracking on all services</li>
        </ul>

        <p className="lead font-weight-bold">Send a pallet from only £67.99 exc VAT</p>

        <h2>About Palletways Couriers</h2>
        <p>
            As Europe’s largest pallet delivery network, Palletways have forged an impressive reputation for exceptional service and breadth of coverage. Originally founded by Andy Hibbert and Steve Aston in 1996, it is now owned by Imperial Holdings Ltd.
        </p>
        <p>
            Palletways now has over 400 depots strategically positioned across 28 countries, offering both frequent and infrequent senders a direct link to the continent that is simply unrivalled.
        </p>
        <p>
            Because of this, Palletways delivery is the pallet delivery courier of choice for hundreds of businesses throughout the UK and Europe. Their vast network of Palletways depots and couriers enables them to deliver goods quicker and more affordably than ever before, with an ever-growing pallet network delivering up to 40,000 pallets a day.
        </p>
        <p>
            Palletways UK and international delivery prices start from £0.00 exc VAT. Find out how to get cheap pallet delivery with our <a href="#">quick quote tool</a>.
        </p>

        <h3>Palletways' Choice of 5 Pallets</h3>
        <p>You can choose between five sizes of pallet for local and international pallet delivery:</p>
        <ul className="list-group circle-list mb-4">
            <li className="list-group-item"><strong>Mini Quarter Pallet:</strong> 120cm x 100cm x 60cm, up to 150kg</li>
            <li className="list-group-item"><strong>Light Pallet:</strong> 120cm x 100cm x 220cm, up to 750kg</li>
            <li className="list-group-item"><strong>Quarter Pallet:</strong> 120cm x 100cm x 80cm, up to 250kg</li>
            <li className="list-group-item"><strong>Half Pallet:</strong> 120cm x 100cm x 110cm, up to 500kg</li>
            <li className="list-group-item"><strong>Full Pallet:</strong> 120cm x 100cm x 220cm, up to 1200kg</li>
        </ul>
        <p>Learn more on our <a href="#">pallet delivery page</a>.</p>

        <h2>Why Choose Palletways Delivery with ParcelLodge?</h2>
        <p>
            When you book your pallet through ParcelLodge, you’ll not only receive top-quality service but also benefit from our renowned 24-7 customer support, comprehensive tracking, and management functionality, all at incredibly low prices, starting at just £39.20 ex VAT.
        </p>

        <ul className="list-group circle-list mb-4">
            <li className="list-group-item">A lowest price guarantee on deliveries to Europe and the UK</li>
            <li className="list-group-item">24-hour customer service</li>
            <li className="list-group-item">The UK's number one comparison site</li>
        </ul>

        <h3>FAQs</h3>
        <div className="accordion" id="faqAccordion">
  <div className="card">
    <div className="card-header" id="headingOne">
      <h5 className="mb-0">
        <button className="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          How much does Palletways cost for pallet collection and delivery?
        </button>
      </h5>
    </div>
    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
      <div className="card-body">
        The cost of pallet shipping with Palletways depends on what you’re sending and where you’re sending it. Prices for cheap pallet delivery start at £17.75 exc VAT - check how much your delivery will cost you with our quick quote tool.
      </div>
    </div>
  </div>

  <div className="card">
    <div className="card-header" id="headingTwo">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          What type of services do Palletways couriers offer?
        </button>
      </h5>
    </div>
    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
      <div className="card-body">
        Palletways offers several services for cheap pallet shipping. You can ship heavy items, large pallets, and international freight with their express pallet delivery service. The Premium delivery option takes 1-2 days, and the Economy option takes 2-3 days.
      </div>
    </div>
  </div>

  <div className="card">
    <div className="card-header" id="headingThree">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          How long do Palletways take to deliver?
        </button>
      </h5>
    </div>
    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
      <div className="card-body">
        Standard delivery times start from 9am and end at 5pm from Monday to Friday. You are able to request an "AM Delivery", which means your pallet will be delivered before noon. Every pallet delivery has a two-hour delivery window, and you can track your pallet’s journey from collection to delivery.
      </div>
    </div>
  </div>

  <div className="card">
    <div className="card-header" id="headingFour">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Where are Palletways depots located?
        </button>
      </h5>
    </div>
    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
      <div className="card-body">
        You can find your nearest Palletways depots using this locator or get a price with our quick quote tool. Your parcel will be collected by the nearest depot location.
      </div>
    </div>
  </div>

  <div className="card">
    <div className="card-header" id="headingFive">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Do Palletways deliver on Saturdays and Sundays?
        </button>
      </h5>
    </div>
    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-bs-parent="#faqAccordion">
      <div className="card-body">
        Palletways do not deliver on Saturdays, Sundays, or Bank Holidays. If you need a pallet delivered during the weekend, view our alternative pallet shipping options.
      </div>
    </div>
  </div>
</div>


    </div>



                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default Pallet;
        