import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class UPS extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
                        <div className="container mt-10 my-5">
    <div class="col-md-4">
                        <p class="breadcrumbs mb-5">
                            <span className='send-breadcrumb'><a href="/">Home <i class="ion-ios-arrow-forward"></i></a><a href="/ups" className='ml-1'>UPS</a></span>
                            
                             </p></div>
        <h1 className="mb-4">Why send a parcel with UPS?</h1>
        <ul className="list-group mb-4">
            <li className="list-group-item">Collections available between 9am and 5:30pm</li>
            <li className="list-group-item">Parcel protection worth up to £10,000 on selected services</li>
            <li className="list-group-item">Send up to 65kg per parcel</li>
            <li className="list-group-item">Free parcel tracking available on all services</li>
        </ul>

        <h2 className="mb-3">About UPS Couriers</h2>
        <p>
            UPS is the world’s largest parcel delivery company. Originally called the American Messenger Company when it was founded in 1907, UPS (or the United Parcel Service) now delivers over 15 million packages a day around the world.
        </p>
        <p>
            UPS delivery offers a broad range of solutions for the transportation of packages and freight, including innovative delivery options for the global consumer market; the facilitation of international trade, and the deployment of advanced technology to more efficiently manage the world of business.
        </p>
        <p>
            Get a UPS quote today with our quick quote tool. UPS delivery starts from £8.74 exc VAT - find out how much it will cost you now.
        </p>

        <h2 className="mb-3">UPS UK Services</h2>
        <p>
            UPS UK delivery is one of the most trusted services, offering a variety of services including next day and timed options. To view the UPS courier’s full range of UK services <a href="#">click here</a>.
        </p>

        <h3 className="mb-3">Super-Fast Delivery</h3>
        <p>
            As the world’s largest courier service, there is a vast network of UPS couriers who can deliver your items within 24 or 48 hours. Timed deliveries of 10:30am or 12pm the next day are also available should you require more expedited UPS postage.
        </p>

        <h3 className="mb-3">International UPS Services</h3>
        <p>
            Delivering more than 15 million parcels every day across 200 countries and territories, UPS’ courier service is on hand to take care of your international deliveries and imports. Find the full range of UPS international shipping services above.
        </p>

        <h2 className="mb-3">Why choose UPS delivery with Parcellodge?</h2>
        <p>
            Here at Parcellodge, we only work with brands that we trust, which is why when you book through us you can count on UPS to get your goods from A to B without a hitch. With UPS’s extensive experience of handling all kinds of consignments, you can be sure that your UPS international delivery will be taken every care of.
        </p>
        <p>
            And when you book a UPS courier service through Parcellodge, you’ll always get it at the lowest possible price.
        </p>
        <ul className="list-group mb-4">
            <li className="list-group-item">A lowest price guarantee on deliveries to Europe and the UK</li>
            <li className="list-group-item">24 hour customer service</li>
            <li className="list-group-item">The UK's number one comparison site</li>
        </ul>

        <h2 className="mb-3">FAQs</h2>

        <div className="accordion" id="faqs">
            <div className="accordion-item">
                <h2 className="accordion-header" id="faq1">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                        How much does UPS parcel collection & delivery cost?
                    </button>
                </h2>
                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="faq1" data-bs-parent="#faqs">
                    <div className="accordion-body">
                        You can see how much it is to send a parcel with UPS with our quick quote tool. UPS prices start from £8.74 exc VAT - get your UPS quote today.
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="faq2">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                        What type of courier services does UPS offer?
                    </button>
                </h2>
                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="faq2" data-bs-parent="#faqs">
                    <div className="accordion-body">
                        UPS’ courier service ranges from door to door deliveries to convenient drop off and pick up locations through UPS Access Point. You can book next day, timed and weekend deliveries. UPS also has an Express Saver service for customers looking for value.
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="faq3">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                        How to send a parcel through UPS?
                    </button>
                </h2>
                <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="faq3" data-bs-parent="#faqs">
                    <div className="accordion-body">
                        To send a parcel through UPS, book your UPS collection or drop off through our quick quote tool. You’ll be able to track your post or large parcel shipping throughout its journey.
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="faq4">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                        How long does it take UPS to deliver parcels?
                    </button>
                </h2>
                <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="faq4" data-bs-parent="#faqs">
                    <div className="accordion-body">
                        UPS delivery times are usually from Monday to Friday between 9am and 5:30pm to individual residences, and normal business hours for business recipients. Depending on which UPS courier service you choose, it can take from 24 hours to a few days for delivery.
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="faq5">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                        Do UPS deliver on Saturdays and Sundays?
                    </button>
                </h2>
                <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="faq5" data-bs-parent="#faqs">
                    <div className="accordion-body">
                        UPS UK delivery does happen at the weekend on Saturdays, but there is no delivery for UPS on Sundays.
                    </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header" id="faq6">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                        What's the difference between UPS Access Point and UPS?
                    </button>
                </h2>
                <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="faq6" data-bs-parent="#faqs">
                    <div className="accordion-body">
                        UPS Access Points are convenient locations where you can post parcels for shipping with UPS courier services. UPS is a door to door delivery service, where you can arrange a collection from a place of your choice.
                    </div>
                </div>
            </div>
        </div>
    </div>


                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default UPS;
        