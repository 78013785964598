import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class DPD extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
                        <div className="container mt-10 my-5">
    <div class="col-md-4">
                        <p class="breadcrumbs mb-5">
                            <span className='send-breadcrumb'><a href="/">Home <i class="ion-ios-arrow-forward"></i></a><a href="/dpd" className='ml-1'>DPD</a></span>
                            
                             </p></div>
        <h1 className="mb-4">Why send a parcel with DPD?</h1>
        <ul className="list-unstyled">
            <li>Exclusive tracking features with DPD Predict</li>
            <li>Over 6,000 Drop Off Points</li>
            <li>Parcel Protection up to £10,000 on selected services</li>
            <li>Send up to 30kg per parcel</li>
        </ul>
        <p className="font-weight-bold">Send a parcel from only £7.45 exc VAT</p>

        <h2 className="mt-5">About DPD Couriers</h2>
        <p>
            As one of Europe’s biggest parcel services, DPD is owned by La Poste, a French company and the second largest postal group in Europe. 
            DPD - which stands for Dynamic Parcel Distribution - has been around in several forms since the 1980s, delivering over 300 million parcels every year.
        </p>
        <p>
            With over 500 depots in 185 countries and territories, you can rest assured that DPD courier services are tailored to meet high demand both locally and internationally. 
            DPD Express and other shipping services offer quick and reliable door-to-door services across the UK and international locations.
        </p>
        <p>
            Whether you are a one-time sender or need to send in bulk for your e-commerce shop, DPD offers a service for all types of senders. 
            If you need to ship worldwide, DPD is well-equipped to handle any demand, allowing you to send up to 30kg per parcel.
        </p>
        <p>
            With DPD drop-off services and DPD collection points available, DPD’s extensive network across the UK has got you covered. 
            You’ll always get the best possible DPD courier service to ensure your parcels get from A to B safely and on time, without hassle!
        </p>
        <p>
            When you ship with ParcelLodge, you’ll always get the most competitive prices from DPD! DPD prices start from as low as £7.45 exc VAT - 
            find out your cost with our quick quote tool now.
        </p>

        <h2 className="mt-5">UK Services</h2>
        <p>
            DPD is one of the UK’s most trusted parcel delivery specialists, offering a variety of delivery options. 
            Use DPD to send parcels or find a DPD pick-up shop to collect items sent to you easily and quickly.
        </p>
        <p>View the full range of UK services from DPD shipping.</p>

        <h2 className="mt-5">Drop-off Services</h2>
        <p>
            Send a parcel with DPD anytime at any of the 6,000 DPD Drop Shop locations around the UK. 
            Easily track the progress of your parcel in real-time or rearrange delivery while in transit, all from your smartphone.
        </p>

        <h2 className="mt-5">Super-fast Delivery</h2>
        <p>
            Once your parcel is in their possession, DPD couriers deliver within 1-3 working days as standard. 
            Next-day delivery options are also available should you require a more expedited service. 
            You can find DPD pick-up shops and DPD collection points near you, or get DPD collection from home.
        </p>

        <h2 className="mt-5">International Services</h2>
        <p>
            DPD offers a wide range of international delivery solutions worldwide. Find out more about DPD’s flexible services to Europe and the rest of the world.
        </p>

        <h2 className="mt-5">Why choose DPD delivery with ParcelLodge?</h2>
        <ul className="list-unstyled">
            <li>A lowest price guarantee on deliveries to Europe and the UK</li>
            <li>24-hour customer service</li>
            <li>The UK's number one comparison site</li>
        </ul>

        <h2 className="mt-5">FAQs</h2>
        <h5>What type of courier services does DPD offer?</h5>
        <p>
            You can get DPD collection or DPD drop-off services for your package. Whether you’re sending a parcel to the UK or to an international location, 
            you can easily send a parcel with DPD. Choose from a variety of services now - just get a quote from our quick quote tool to get started.
        </p>

        <h5>How much is it to send a parcel with DPD?</h5>
        <p>
            Find out how much DPD shipping costs using our quote comparison tool. You’ll be able to enter in your parcel details and see DPD prices listed for your specific package. 
            Prices start from as little as £7.45 exc VAT.
        </p>

        <h5>What time does DPD deliver until?</h5>
        <p>
            DPD’s delivery times usually start at 8am, and they stop delivery at around 6pm, though this may vary. DPD doesn’t deliver during bank holidays.
        </p>

        <h5>What is DPD Local? How does it compare to DPD?</h5>
        <p>
            DPD Local is the UK branch of the DPD brand. Both use the same technology and logistics systems to get your parcel where it needs to go, 
            but DPD Local is better for individuals or businesses that have lower shipping volumes. DPD can handle larger shipments more easily.
        </p>

        <h5>Where can I drop off my DPD parcel?</h5>
        <p>
            You can use one of the 6,000 drop-off points or DPD pick-up shops to send your parcel, making it super convenient to get your items where they need to go.
        </p>
    </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default DPD;
        