import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class FedEx extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
            
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
    <div className="container mt-10 my-5">
    <div class="col-md-4">
                        <p class="breadcrumbs mb-5">
                            <span className='send-breadcrumb'><a href="/">Home <i class="ion-ios-arrow-forward"></i></a><a href="/fedex" className='ml-1'>Fedex</a></span>
                            
                             </p></div>
        
        <h1 className="mb-4">Why Send a Parcel with FedEx Express?</h1>
        <ul>
            <li>Send overseas in 3 working days</li>
            <li>Parcel protection worth up to £10,000 on selected services</li>
            <li>Send parcels up to 120cm in length</li>
            <li>Over 4,600 drop off points</li>
        </ul>

        <h2 className="mt-5 mb-3">About FedEx Express Couriers</h2>
        <p>
            The FedEx Express delivery service was founded as “Federal Express” in 1971 in a small town in Arkansas, United States.
            From there, FedEx Express has grown consistently and now has over 280,000 employees.
        </p>
        <p>
            FedEx Express international courier services now have a fleet of over 671 aircraft and 43,000 road vehicles across the world. 
            They also have 1,200 service centres across the globe. Approximately 3.1 million packages are delivered by FedEx Express worldwide each day.
        </p>
        <p>
            Book door-to-door delivery or drop your parcel off at one of 4,600 drop-off locations with FedEx Express today.
            Get your quick FedEx Express quote now with our quick quote tool.
        </p>

        <h3 className="mt-5 mb-3">Economy Service</h3>
        <p>
            FedEx Express courier services are highly reputable, and by choosing to send your parcel through us, you can rest assured 
            it will arrive quickly and safely. FedEx Express Economy delivery offers worldwide delivery within 3-5 working days 
            and can be tracked from collection until it reaches its recipient. You can use a FedEx Express drop-off point to send 
            your parcel across the UK or internationally from door to door.
        </p>

        <h3 className="mt-5 mb-3">Express Delivery</h3>
        <p>
            Once your FedEx Express parcel is collected from a FedEx Express collection point or from your home, your item will be 
            delivered within 2-3 working days throughout worldwide destinations. And thanks to the latest technology, you can check 
            the progress of your parcel online with FedEx Express international delivery tracking.
        </p>

        <h2 className="mt-5 mb-3">Why Choose FedEx Express Delivery with Parcel2Go?</h2>
        <ul>
            <li>A lowest price guarantee on deliveries to Europe and the UK</li>
            <li>24-hour customer service</li>
            <li>The UK's number one comparison site</li>
        </ul>

        <h2 className="mt-5 mb-3">FAQs</h2>

        <h4>How much does FedEx Express parcel delivery cost?</h4>
        <p>
            You can find cheap prices for a FedEx Express delivery using our quick quote tool. Prices for FedEx Express UK courier 
            services start from £3.45 exc VAT. You can also select FedEx International from just £12.32 exc VAT.
        </p>

        <h4>What type of courier services does FedEx Express offer?</h4>
        <p>
            You can book a FedEx Express collection from your home or from the location of your choice. You can also go to a FedEx 
            Express drop-off point. If you need, you can book FedEx Express shipping to international destinations or from door to door in the UK.
        </p>

        <h4>How long does FedEx Express take to deliver parcels?</h4>
        <p>
            How late does FedEx Express deliver until? Delivery times are usually from 8am to 8pm Monday to Friday, but it depends 
            on the service you choose. If you want to check when they stop delivering for your parcel, you can track using FedEx Express’ shipping tracker.
        </p>

        <h4>Are TNT and FedEx Express the same?</h4>
        <p>
            FedEx Express delivery and TNT are now both part of the FedEx offering. FedEx acquired TNT in 2016.
        </p>

        <h4>Does FedEx Express deliver on weekends?</h4>
        <p>
            FedEx Express doesn't offer weekend delivery. Standard delivery times are Monday - Friday between 8am - 8pm.
        </p>
    </div>


                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default FedEx;
        