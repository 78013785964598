import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class RoyalMail extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
                        <div className="container mt-10 my-5">
    <div class="col-md-4">
                        <p class="breadcrumbs mb-5">
                            <span className='send-breadcrumb'><a href="/">Home <i class="ion-ios-arrow-forward"></i></a><a href="/royalmail" className='ml-1'>RoyalMail</a></span>
                            
                             </p></div>
        <h1 className="text-primary mb-4">Why send a parcel with Royal Mail?</h1>
        <ul className="list-unstyled">
            <li><i className="fas fa-check text-success"></i> Over 14,000 drop-off locations across the UK</li>
            <li><i className="fas fa-check text-success"></i> Free parcel tracking available on all services</li>
            <li><i className="fas fa-check text-success"></i> UK Delivery in just 1-2 days</li>
            <li><i className="fas fa-check text-success"></i> Parcels delivered to Europe in just 3-5 days</li>
        </ul>
        <p><strong>Send a parcel from only £2.25 exc VAT</strong></p>

        <h2 className="text-secondary mt-5">About Royal Mail Couriers</h2>
        <p><strong>Who is Royal Mail?</strong></p>
        <p>Delivering parcels for over 500 years, Royal Mail is one of the most trusted couriers in the UK. Initially created by King Henry VIII and used by royalty, the modern Royal Mail now has over 14,000 drop off points and many convenient services that anyone can use.</p>
        <p>It’s easy to send a parcel with Royal Mail throughout the UK when you book with Parcellodge. With free tracking and inclusive protection on all Royal Mail parcels, you’ll always have peace of mind when you book with us! As the UK’s most popular courier service, you can rely on your Royal Mail delivery to arrive safely in just 1-2 working days.</p>
        <p>With our Royal Mail International services, your parcel can be delivered to over 178 destinations worldwide in just 3-7 working days! There’s also no need to worry about labeling your parcel when you book a Royal Mail collection service. When the driver arrives to collect your parcel, they’ll attach a shipping label for you.</p>
        <p>When you ship with Parcellodge, you’ll always get the best value for your money. Get a quote today!</p>

        <h3 className="text-secondary mt-4">Royal Mail Drop-off Services</h3>
        <p>Buy your postage, print your label, and drop your parcel off at one of 14,000 Royal Mail drop-off points around the UK for a stress-free delivery with Royal Mail Click and Drop near you. From there, you can easily track your parcel as it makes the journey to its final destination with Royal Mail's door-to-door service.</p>

        <h4 className="mt-4">Royal Mail Super-fast Delivery</h4>
        <p>With Royal Mail, your parcels can be delivered throughout the UK in just 1-2 days. Or, if you want to send internationally, it will arrive within 3-7 days. No matter where you’re sending to, you can rely on Royal Mail to deliver quickly and reliably. And when you book with Parcellodge, you can track your parcel for free.</p>

        <h4 className="mt-4">Royal Mail International Services</h4>
        <p>Offering shipping to some of the world’s most popular destinations, you can send your parcel to one of 178 destinations with Royal Mail’s worldwide services. From large letters to heavy parcels weighing up to 20kg - you’ll always find an international service to suit you with Royal Mail. Get a Royal Mail quote with Parcellodge today.</p>

        <h2 className="text-secondary mt-5">Why choose Royal Mail delivery with Parcellodge?</h2>
        <p>When you book your Royal Mail service through Parcellodge, you can rest assured that your parcel will reach its destination quickly and safely. Royal Mail has a great reputation for fast and secure parcel delivery and when you book a Royal Mail courier service through Parcellodge, you’ll always get it at the lowest possible price.</p>
        <ul className="list-unstyled">
            <li><i className="fas fa-check text-success"></i> A lowest price guarantee on deliveries to Europe and the UK</li>
            <li><i className="fas fa-check text-success"></i> 24 hour customer service</li>
            <li><i className="fas fa-check text-success"></i> The UK's number one comparison site</li>
        </ul>

        <h2 className="text-secondary mt-5">FAQs</h2>
        <h5>How much is it to send a parcel with Royal Mail?</h5>
        <p>You can send your Royal Mail parcel throughout the UK from just £2.82. Or, if you want to send your Royal Mail parcel internationally, prices start from £8.00 with Parcellodge. Use our quick quote tool to find out how much it is to send a parcel with Royal Mail.</p>

        <h5>How do I send a parcel with Royal Mail?</h5>
        <p>There are plenty of Royal Mail delivery options to choose from across the UK and the world. For our full range of Royal Mail services, simply get a quote and enter the details of the parcel you want to send. And if you need more information on our Royal Mail parcel services, see our detailed Royal Mail FAQs.</p>

        <h5>Do I need stamps for my Royal Mail package?</h5>
        <p>If you’re booking a Royal Mail Large Letter delivery, you will need to include postage stamps. The amount of stamps you need will depend on the size and weight of your letter. See how to send a large letter internationally for more information! You won’t need stamps for all other Royal Mail services with Parcellodge, but you will need a label. With Royal Mail Click and Drop, you can sort your label out at home to conveniently buy postage online before dropping off your parcel. Or if you book a Royal Mail collection, the driver will bring the label to you.</p>

        <h5>What time does Royal Mail deliver until?</h5>
        <p>Royal Mail delivery times may vary by service, but generally it aims to deliver post by 3pm for urban areas, with last delivery times by 4pm for rural areas. This may vary if there are disruptions such as traffic.</p>

        <h5>Do Royal Mail deliver on Saturdays and Sundays?</h5>
        <p>Royal Mail does offer weekend delivery on Saturdays and Sundays. However, they don’t deliver or collect on Bank Holidays.</p>

        <h5>How long does Royal Mail delivery take?</h5>
        <p>With Royal Mail, your parcels can be delivered across the UK in just 1-2 working days. Or, if you want to send your parcels internationally, your parcel will be delivered in 3-7 working days.</p>

        <h5>Are Royal Mail on Strike?</h5>
        <p>You can check how long Royal Mail are on strike on our Royal Mail strike page.</p>
    </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default RoyalMail;
        