import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class ParcelForce extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
                        <div className="container mt-10 my-5">
    <div class="col-md-4">
                        <p class="breadcrumbs mb-5">
                            <span className='send-breadcrumb'><a href="/">Home <i class="ion-ios-arrow-forward"></i></a><a href="/parcelforce" className='ml-1'>ParcelForce</a></span>
                            
                             </p></div>
        <h1 className="mb-4">Why Send a Parcel with Parcelforce?</h1>
        <ul>
            <li>Over 11,500 drop-off locations available across the UK</li>
            <li>Parcel protection worth up to £10,000 on selected services</li>
            <li>Send up to 30kg per parcel</li>
            <li>Free tracking on all services</li>
        </ul>
        <p className="mt-3"><strong>Send a parcel from only £7.65 exc VAT</strong></p>

        <h2 className="mt-5 mb-3">About Parcelforce Couriers</h2>

        <h3>Who is Parcelforce?</h3>
        <p>
            With over 25 years of experience, Parcelforce is one of the leading couriers in the parcel delivery industry. Initially launched as part of the Royal Mail Group, in 1990 it changed to Parcelforce from Royal Mail Parcels. As of 1998, it’s been known as Parcelforce Worldwide. It now delivers over 60,000 parcels every hour, meeting the demands of millions of customers.
        </p>
        <p>
            There are over 11,500 Parcelforce drop off points and 54 Parcelforce depots across the UK, so you’ll be able to use their services wherever you are. The Parcelforce door-to-door collection service allows you to avoid the queues in the drop shops and have your parcel collected at a time that suits you.
        </p>
        <p>
            Parcelforce offers a service for all types of senders. Whether you are looking to ship a parcel within the UK or worldwide, they’ve got you covered. Parcelforce 24 hour and Parcelforce 48 hour sending services also allow you to get your items to your recipient quickly.
        </p>
        <p>
            Find the best Parcelforce service quote with Parcellodge today! Use our quick quote tool to find out how much it will cost you now.
        </p>

        <h2 className="mt-5 mb-3">Parcelforce UK Services</h2>
        <p>
            Parcelforce is one of the most trusted couriers in the UK and around the world. Offering a variety of services including next day and timed delivery, you can <a href="#">click here</a> to view Parcelforce UK’s full range of services.
        </p>

        <h3 className="mt-4 mb-3">Parcelforce Drop Off Services</h3>
        <p>
            Drop your parcel off at any of the 11,500 Parcelforce drop off points around the UK, for hassle-free delivery within the UK and internationally. Easily track your parcel from collection until it reaches its final destination.
        </p>

        <h3 className="mt-4 mb-3">Parcelforce Super-fast Delivery</h3>
        <p>
            With such a large network of couriers and drop-off locations, Parcelforce can send a parcel and deliver your items within either 24 or 48 hours via collection or a drop-off service. Timed deliveries of 9am, 10am, or 12pm the next day are also available should you require a more expedited service.
        </p>

        <h3 className="mt-4 mb-3">Parcelforce International Services</h3>
        <p>
            Delivering more than 7 million parcels every year across 185 countries and territories, Parcelforce is on hand to take care of your international deliveries. They offer a variety of services including collection and drop off. View their full range of international services above.
        </p>

        <h2 className="mt-5 mb-3">Why Choose Parcelforce Delivery with Parcellodge?</h2>
        <ul>
            <li>A lowest price guarantee on deliveries to Europe and the UK</li>
            <li>24-hour customer service</li>
            <li>The UK's number one comparison site</li>
        </ul>

        <h2 className="mt-5 mb-3">FAQs</h2>

        <h4 className="mt-4">What type of courier services do Parcelforce offer?</h4>
        <p>
            Parcelforce UK offers a number of courier services, including Express 9am, Express 10am, Express AM, Express 24 hour, Express 48 hour and Express 48 hour large for all your timed delivery, AM delivery, and next day delivery needs. Parcelforce Worldwide also offers Euro Economy for Drop Off and Collection, Global Express, Global Priority, Global Value, Global Economy and Ireland Express.
        </p>

        <h4 className="mt-4">What times do Parcelforce couriers deliver until?</h4>
        <p>
            Deliveries to Parcelforce UK recipients are generally made between 7.00am and 6.00pm from Monday to Friday, but how long it takes depends on the service you choose.
        </p>

        <h4 className="mt-4">Do Parcelforce deliver on Saturdays and Sundays?</h4>
        <p>
            Parcelforce do not deliver on weekends or Bank Holidays with Parcellodge. Standard delivery times are Monday to Friday between 7am - 6pm.
        </p>

        <h4 className="mt-4">Is Parcelforce part of Royal Mail?</h4>
        <p>
            Parcelforce Worldwide is not part of Royal Mail now, though it was originally part of Royal Mail when it was created. They became separate businesses in 1990.
        </p>

        <h4 className="mt-4">Are Parcelforce on Strike?</h4>
        <p>
            You can check if there are strike actions that will affect your Parcelforce delivery <a href="#">here</a>.
        </p>

        <h4 className="mt-4">Can I drop my Parcelforce package at the post office?</h4>
        <p>
            You can drop most parcels off for Parcelforce delivery at the nearest Post Office. It’s best to check how you can use Parcelforce to send a parcel with our quick quote tool, which will list your closest drop off points.
        </p>
    </div>


                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default ParcelForce;
        